import React from "react";
import { Grid, Paper, Box, Button, Modal } from "@mui/material";
import "./work.css";
import "../App.css";

export default function JobGridItem({
  gridWidth = 6,
  image,
  altImage,
  modalData,
  secondJob = false,
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Grid item xs={gridWidth}>
        <div className="paper">
          <img src={image} alt={altImage} />
          <div className="center">
            <Button
              variant="contained"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Learn More
            </Button>
          </div>
        </div>
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        className="modal"
      >
        <Paper className="modalContent">
          <a href={modalData.website}>
            <h1 className="resetText">{modalData.title}</h1>
          </a>
          <p>{modalData.company_description}</p>
          <JobItem jobData={modalData.jobs[0]} />
          {secondJob && <JobItem jobData={modalData.jobs[1]} />}
          <Button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Close modal
          </Button>
        </Paper>
      </Modal>
    </>
  );
}

function JobItem(jobData) {
  return (
    <>
      <h2 className="resetText">
        {jobData.jobData.job_title} ({jobData.jobData.job_dates})
      </h2>
      <p>{jobData.jobData.job_description}</p>
      <ul>
        {jobData.jobData.job_bulletPoints.map((data) => (
          <li>{data}</li>
        ))}
      </ul>
    </>
  );
}

import React, { useState, useEffect } from "react";
import WebBanner from "../utils/WebBanner.js";
import JobGridItem from "./JobGridItem.js";
import "./work.css";
import "../App.css";
import { Grid, Paper, Button } from "@mui/material";
import NCRlogo from "./NCRlogo.png";
import Monogramlogo from "./Monogramlogo.jpeg";
import BIDMC from "./BIDMC.jpeg";
import StarMarket from "./StarMarket.png";
import NNTV from "./nntv.jpeg";
import Catalyst from "./CatalystLogo-orange-black.png";
import Tesla from "./Tesla_logo.png";
import details from "./details.json";
import Resume from "./NicolasQuintanaResume.pdf";
import useWindowSize from "../utils/useWindowSize.js";
import CLogo from "../projects/c.png";
import CPlusLogo from "./c++.png";
import JSLogo from "./JS.png";
import JavaLogo from "./Java.png";
import ImageWithDesc from "../creative/ImageWithDesc.js";
import SQLLogo from "./SQL-logo.png";
import PythonLogo from "./python.png";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { pageTransition } from "../PageAnimation.js";

export default function Work({ updatepageNum, pageVariants }) {
  updatepageNum(3);
  document.title = "Nicolás Quintana | Work Experience";

  let gridWidth = 6;
  const { width } = useWindowSize();
  if (width < 768) {
    gridWidth = 12;
  } else {
    width <= 1440 ? (gridWidth = 6) : (gridWidth = 4);
  }

  useEffect(() => {
    if (width < 768) {
      gridWidth = 12;
    } else {
      width <= 1440 ? (gridWidth = 6) : (gridWidth = 3);
    }
  }, [width]);

  return (
    <motion.div
      className="mainContainer"
      role="main"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Paper className="mainPaper">
        <h1>Work Experience</h1>

        <h2>Resume</h2>
        <div className="languagesContainer">
          <Button
            variant="contained"
            size="large"
            onClick={() => window.open(Resume, "_blank")}
          >
            View my latest Resume
          </Button>
        </div>

        <h2>Previous Employers</h2>
        <p className="App">Click to see more details</p>
        <Grid container spacing={2} className="gridContainer">
          <JobGridItem
            gridWidth={gridWidth}
            image={Tesla}
            altImage="https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png"
            modalData={details.Tesla}
            secondJob={false}
          />
          <JobGridItem
            gridWidth={gridWidth}
            image={Catalyst}
            altImage="https://i0.wp.com/catalystreaction.com/wp-content/uploads/2023/02/Catalyst-logo-layeredpsd.png?w=1000&ssl=1"
            modalData={details.Catalyst}
            secondJob={false}
          />
          <JobGridItem
            gridWidth={gridWidth}
            image={NCRlogo}
            altImage="https://upload.wikimedia.org/wikipedia/en/thumb/b/b1/NCR_Corporation_logo.svg/1200px-NCR_Corporation_logo.svg.png"
            modalData={details.NCR}
            secondJob={true}
          />
          <JobGridItem
            gridWidth={gridWidth}
            image={Monogramlogo}
            altImage="https://yt3.ggpht.com/ytc/AKedOLQzOIt0xGACXGZ91YXWKW-aoIA02wkxKj43cae0WA=s900-c-k-c0x00ffffff-no-rj"
            modalData={details.Monogram}
          />
          <JobGridItem
            gridWidth={gridWidth}
            image={BIDMC}
            altImage="https://brookline.com/wp-content/uploads/2017/04/Beth-Israel-Deaconess-Medical-Center.jpg"
            modalData={details.BIDMC}
            secondJob={true}
          />
          <JobGridItem
            gridWidth={gridWidth}
            image={NNTV}
            altImage="https://yt3.ggpht.com/ytc/AKedOLT8V9zHADeM0063RcOnf-zN58u6g9yUY3MSAc9lpA=s900-c-k-c0x00ffffff-no-rj"
            modalData={details.NNTV}
          />
          <JobGridItem
            gridWidth={gridWidth}
            image={StarMarket}
            altImage="https://dynl.mktgcdn.com/p/x8zRyi_Up71qxH3csDFGA8JW_V_Z0jN_omOD9qf8aTc/1439x1439.png"
            modalData={details.Star}
          />
        </Grid>

        <h2>Coding Languages</h2>
        <div className="languagesContainer">
          <ImageWithDesc
            image={CLogo}
            className="languages"
            desc="Used in 2 Waterloo courses and design team."
            alt="C language"
          />
          <ImageWithDesc
            image={CPlusLogo}
            className="languages"
            desc="Used in 2 Waterloo courses, at one co-op position, and two side projects."
            alt="C++ language"
          />
          <ImageWithDesc
            image={JSLogo}
            className="languages"
            desc="Used in 3 co-op positions and one side project."
            alt="Javascript language"
          />
          <ImageWithDesc
            image={JavaLogo}
            className="languages"
            desc="Completed CodeAcademy course."
            alt="Java language"
          />
          <ImageWithDesc
            image={SQLLogo}
            desc="Completed CodeAcademy course."
            alt="SQL language"
          />
          <ImageWithDesc
            image={PythonLogo}
            desc="Completed CodeAcademy course."
            alt="Python language"
          />
        </div>
      </Paper>
    </motion.div>
  );
}

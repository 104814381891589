import React, { useState } from "react";
// import { makeStyles } from '@mui/core';
import { Tabs, Tab, Menu, MenuItem } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import "../App.css";
import { NavLink } from "react-router-dom";

/*const useStyles = makeStyles({

})*/

export default function WebBanner({ pageNum, setpageNum }) {
  /*const [value, setValue] = useState(pageNum);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };*/
  return (
    <Tabs
      value={pageNum}
      onChange={() => {}}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="navbar"
      className="navbar"
    >
      <NavLink to="/" className="navLink">
        <Tab label="Home" className="navItem" />
      </NavLink>
      <NavLink to="/projects" className="navLink">
        <Tab label="Engineering Projects" className="navItem" />
      </NavLink>
      <NavLink to="/creative" className="navLink">
        <Tab label="Creative Endeavors" className="navItem" />
      </NavLink>
      <NavLink to="/work" className="navLink">
        <Tab label="Work Experience" className="navItem" />
      </NavLink>
      <Tab
        icon={<LinkedInIcon />}
        aria-label="Linkedin"
        className="navItem"
        onClick={() => {
          window.open(
            "https://www.linkedin.com/in/nicolasmquintana/",
            "_blank"
          );
        }}
      />
      <Tab
        icon={<GitHubIcon />}
        aria-label="GitHub"
        className="navItem"
        onClick={() => {
          window.open("https://github.com/nquintan", "_blank");
        }}
      />
    </Tabs>
  );
}

import React from "react";
import WebBanner from "../utils/WebBanner.js";
import ImageWithDesc from "./ImageWithDesc.js";
import MASPA from "./MASPA.jpg";
import POFY from "./POFY.JPG";
import NSPA from "./NSPA.jpeg";
import DiaAward from "./DiaAward.png";
import "./creative.css";
import { Button, Paper } from "@mui/material";
import "../App.css";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { pageTransition } from "../PageAnimation.js";

const maspaDesc =
  "After submitting my portfolio, I was chosen as one of the top six journalists in Massachusetts by the Massachusetts Scholastic Press Association.";
const pofyDesc =
  "I was awarded by the directors of the TV Media Arts Program as the Producer of the year in my senior year.";
const nspaDesc =
  'I was one of the producers for the NSPA-nominated "Puerto Rico: Rising from Ruin". I was the only senior producer during the filming of this documentary, so I wasn\'t apart of the editing process. Regardless, working on this documentary was one of the highlights of my career so far.';
const diaDesc =
  "I was awarded this New England Scholastic Press Award in the special localizing category during my senior year.";

export default function Creative({ updatepageNum, pageVariants }) {
  updatepageNum(2);
  document.title = "Nicolás Quintana | Creative Endeavors";

  return (
    <motion.div
      className="mainContainer"
      role="main"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Paper className="mainPaper">
        <h1>Creative Endeavors</h1>
        <p>
          During high school, I majored in TV Media Arts and was a video
          journalist for our high school's two TV shows. In my senior year, I
          was elected by my peers to be the producer of the Tiger Vision TV
          show. In addition to TV journalism, I also wrote for my school's
          newspaper, announced football games, photographed for the yearbook,
          produced content for the unofficial student run newspaper, and
          introduced my TV program to live-streaming our highschool's events.
          Below, you can see a few of my favorite pieces I worked on as well as
          the awards I've won for my journalism.
        </p>
        <h2>Featured Content</h2>
        <div className="videoContainer">
          <iframe
            width="320"
            src="https://www.youtube.com/embed/jOXxmaAJhhE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
          />
          <iframe
            width="320"
            src="https://www.youtube.com/embed/GIEDhl1-W9c"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
          />
          <iframe
            width="320"
            src="https://www.youtube.com/embed/_GDTHVDPTyQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
          />
          <Button
            variant="contained"
            onClick={() => {
              window.open(
                "https://photos.app.goo.gl/daV8zihAbGaEqiE3A",
                "_blank"
              );
            }}
          >
            Photography Portfolio
          </Button>
        </div>
        <h2>Awards</h2>
        <div className="awardsContainer">
          <ImageWithDesc
            title="Massachusetts All State Journalism Staff"
            image={MASPA}
            desc={maspaDesc}
          />
          <ImageWithDesc
            title="Nominated for National Student Production Award"
            image={NSPA}
            desc={nspaDesc}
          />
          <ImageWithDesc
            title="Producer of the Year"
            image={POFY}
            desc={pofyDesc}
          />
          <ImageWithDesc
            title="New England Scholastic Press Award"
            image={DiaAward}
            desc={diaDesc}
          />
        </div>
      </Paper>
    </motion.div>
  );
}

import React, { useEffect } from "react";
import WebBanner from "../utils/WebBanner.js";
import { Grid, Paper } from "@mui/material";
import ProjectGridItem from "./ProjectGridItem.js";
import "../work/work.css";
import "../App.css";
import StarMarket from "../work/StarMarket.png";
import projectDetails from "./projectDetails.json";
import Nucleo from "./nucleo.jpeg";
import ReactLogo from "./logo512.png";
import QtLogo from "./qt.png";
import CLogo from "./c.png";
import useWindowSize from "../utils/useWindowSize.js";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { pageTransition } from "../PageAnimation.js";

export default function Projects({ updatepageNum, pageVariants }) {
  updatepageNum(1);
  document.title = "Nicolás Quintana | Projects";

  let gridWidth = 6;
  const { width } = useWindowSize();
  if (width < 768) {
    gridWidth = 12;
  } else {
    width <= 1440 ? (gridWidth = 6) : (gridWidth = 4);
  }

  useEffect(() => {
    if (width < 768) {
      gridWidth = 12;
    } else {
      width <= 1440 ? (gridWidth = 6) : (gridWidth = 3);
    }
  }, [width]);

  return (
    <motion.div
      className="mainContainer"
      role="main"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Paper className="mainPaper">
        <h1>Projects</h1>
        <Grid container spacing={2} className="gridContainer">
          <ProjectGridItem
            title="Portfolio Website"
            gridWidth={gridWidth}
            image={ReactLogo}
            altImage="image"
            modalData={projectDetails.website}
          />
          <ProjectGridItem
            title="Wheel Chair Motor Controller"
            gridWidth={gridWidth}
            image={Nucleo}
            altImage="image"
            modalData={projectDetails.wheelChair}
          />
          <ProjectGridItem
            title="ECE252 Projects"
            gridWidth={gridWidth}
            image={CLogo}
            altImage="image"
            modalData={projectDetails.ece252}
          />
          <ProjectGridItem
            title="Qt Projects"
            gridWidth={gridWidth}
            image={QtLogo}
            altImage="image"
            modalData={projectDetails.qt}
          />
        </Grid>
      </Paper>
    </motion.div>
  );
}

import React from "react";
import { Grid, Paper, Button, Modal } from "@mui/material";
import "../work/work.css";
import "../App.css";

export default function ProjectGridItem({
  title = null,
  image,
  altImage,
  modalData,
  gridWidth = 6,
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <Grid item xs={gridWidth}>
        <h1>{title}</h1>
        <div className="paper">
          <img src={image} alt={altImage} />
          <div className="center">
            <Button
              variant="contained"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Learn More
            </Button>
          </div>
        </div>
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        className="modal"
      >
        <Paper className="modalContent">
          <h1 className="resetText">{modalData.title}</h1>
          <h2 className="resetText">Technologies used:</h2>
          <ul>
            {modalData.technologies.map((data) => (
              <li>{data}</li>
            ))}
          </ul>
          <h2 className="resetText">Description:</h2>
          <p>{modalData.desc}</p>
          <Button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Close modal
          </Button>
        </Paper>
      </Modal>
    </>
  );
}

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import "./App.css";
import HomePage from "./homepage/Homepage";
import { Route, Link, Switch, useLocation } from "react-router-dom";
import Projects from "./projects/Projects.js";
import Creative from "./creative/Creative.js";
import Work from "./work/Work.js";
import { useState, useRef, useEffect } from "react";
import WebBanner from "./utils/WebBanner.js";
import { pageVariantLeft, pageVariantRight } from "./PageAnimation.js";
import Error from "./utils/Error.js";

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function App() {
  const location = useLocation();
  const [pageNum, setpageNum] = useState(0);
  const prevpageNum = usePrevious(pageNum);
  const [pageVariants, setpageVariants] = useState(pageVariantLeft);

  /*useEffect(() => {
    prevpageNum > pageNum
      ? setpageVariants(pageVariantRight)
      : setpageVariants(pageVariantLeft);
  }, [pageNum]);*/

  useEffect(() => {
    prevpageNum > pageNum
      ? setpageVariants(pageVariantLeft)
      : setpageVariants(pageVariantLeft);
  }, [pageNum]);

  return (
    <>
      <nav>
        <WebBanner pageNum={pageNum} setpageNum={setpageNum} />
      </nav>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <HomePage updatepageNum={setpageNum} pageVariants={pageVariants} />
          </Route>

          <Route exact path="/projects">
            <Projects updatepageNum={setpageNum} pageVariants={pageVariants} />
          </Route>

          <Route exact path="/Creative">
            <Creative updatepageNum={setpageNum} pageVariants={pageVariants} />
          </Route>

          <Route exact path="/work">
            <Work updatepageNum={setpageNum} pageVariants={pageVariants} />
          </Route>

          <Route path="*">
            <Error updatepageNum={setpageNum} />
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  );
}

export default App;

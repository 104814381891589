import React from "react";
import "./creative.css";

export default function ImageWithDesc({
  title = null,
  image,
  desc,
  alt = null,
}) {
  return (
    <div className="awardItem">
      {title && <h3>{title}</h3>}
      <img src={image} alt={alt} />
      <p>{desc}</p>
    </div>
  );
}

import React from "react";
import { Paper, Button } from "@mui/material";

export default function Work({ updatepageNum }) {
  updatepageNum(null);

  return (
    <div className="mainContainer">
      <Paper className="errorPaper">
        <h1>404 Error, you're lost....</h1>
        <Button
          variant="contained"
          size="large"
          onClick={() => window.open("/", "_self")}
        >
          Take me Home
        </Button>
      </Paper>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import "../App.css";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import WebBanner from "../utils/WebBanner.js";
import moment from "moment";
import { pageTransition } from "../PageAnimation.js";

export default function HomePage({ updatepageNum, pageVariants }) {
  updatepageNum(0);
  document.title = "Nicolás Quintana | Computer Eng. Student";

  const [time, setTime] = useState(null);
  useEffect(() => {
    setInterval(() => {
      const date = moment().format(" hh:mm:ss a");
      setTime(date);
    }, 1000);
  }, []);

  return (
    <div>
      {!!time && (
        <motion.div
          className="homeContent"
          role="main"
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Paper className="homePaper">
            <h1>{time}</h1>
            <h1>Nicolás Quintana</h1>
            <h2>Computer Engineer, Videographer, & Photographer</h2>
          </Paper>
        </motion.div>
      )}
    </div>
  );
}
